<template>
  <v-container fluid>
    <v-card flat class="backgroundApp">
      <v-card-title class="bold">
        Usuários
        <v-divider class="mx-2"></v-divider>
        <v-btn
          icon
          :class="`session${expand ? '--active' : ''}`"
          @click="expand = !expand"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card-title>

      <v-slide-y-transition>
        <v-card-text v-show="expand">
          <v-row>
            <v-col cols="12" md="4">
              <v-card
                flat
                hover
                class="ma-1 px-4 py-2 d-flex align-center"
                @click="dialogHomens = true"
              >
                Persona 1 - Homens
                <v-spacer></v-spacer>
                <v-tooltip bottom dark color="primary" class="pa-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="button_2" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <v-card flat dark class="pb-3">
                    <v-subheader class="pa-0 ma-0">
                      Filtros da Persona
                    </v-subheader>
                    <ul>
                      <li>Homens</li>
                      <li>Até 50 funcionários</li>
                      <li>Renda anual entre R$ 100.000 e R$ 1.000.000</li>
                    </ul>
                  </v-card>
                </v-tooltip>
              </v-card>

              <DialogHomens
                v-if="dialogHomens"
                :dialogHomens.sync="dialogHomens"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-card
                flat
                hover
                class="ma-1 px-4 py-2 d-flex align-center"
                @click="dialogMulheres = true"
              >
                Persona 2 - Mulheres
                <v-spacer></v-spacer>
                <v-tooltip bottom dark color="primary" class="pa-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="button_2" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <v-card flat dark class="pb-3">
                    <v-subheader class="pa-0 ma-0">
                      Filtros da Persona
                    </v-subheader>
                    <ul>
                      <li>Mulheres</li>
                      <li>Até 10 funcionários</li>
                      <li>Renda anual entre R$ 0 e R$ 100.000</li>
                    </ul>
                  </v-card>
                </v-tooltip>
              </v-card>

              <DialogMulheres
                v-if="dialogMulheres"
                :dialogMulheres.sync="dialogMulheres"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-card
                flat
                hover
                class="ma-1 px-4 py-2 d-flex align-center"
                @click="dialogGeral = true"
              >
                Geral
                <v-spacer></v-spacer>
                <v-tooltip bottom dark color="primary" class="pa-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="button_2" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <v-card flat dark class="pb-3">
                    <v-subheader class="pa-0 ma-0">
                      Filtros da Persona
                    </v-subheader>
                    <ul>
                      <li>Todos os Usuários</li>
                    </ul>
                  </v-card>
                </v-tooltip>
              </v-card>

              <DialogGeral v-if="dialogGeral" :dialogGeral.sync="dialogGeral" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-slide-y-transition>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Relatorios_Users",

  components: {
    DialogHomens: () => import("./homens/DialogHomens.vue"),
    DialogMulheres: () => import("./mulheres/DialogMulheres.vue"),
    DialogGeral: () => import("./geral/DialogGeral.vue"),
  },

  data() {
    return {
      expand: true,
      dialogHomens: false,
      dialogMulheres: false,
      dialogGeral: false,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .session--active .v-icon {
  transform: rotate(-180deg);
}
</style>
